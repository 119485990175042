html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
}

a {
  text-decoration: none; /* no underline */
}
